// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainWrapper {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}

.MenuIcon {
  font-weight: 700;
}
.MenuIcon:hover {
  background: #E8ECF1;
  border-radius: 20px;
}

.listBox {
  width: 228px;
  background-color: #F7F7F7;
}

.listMenu {
  padding-bottom: 0px !important;
}

.closeIcon {
  float: right !important;
  width: 60px !important;
  background-color: #F7F7F7;
}

.listText {
  font-weight: 400;
  font-size: 16px;
}

.leftDrawer .MuiPaper-root {
  top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/SideMenu.sass"],"names":[],"mappings":"AAAA;EACI,uBAAA;EAAA,kBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,eAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;AADI;EACI,mBAAA;EACA,mBAAA;AAGR;;AADA;EACI,YAAA;EACA,yBAAA;AAIJ;;AAFA;EACI,8BAAA;AAKJ;;AAHA;EACI,uBAAA;EACA,sBAAA;EACA,yBAAA;AAMJ;;AAJA;EACI,gBAAA;EACA,eAAA;AAOJ;;AALI;EACI,SAAA;AAQR","sourcesContent":[".MainWrapper\n    width: fit-content\n    height: fit-content\n    cursor: pointer\n    \n.MenuIcon\n    font-weight: 700\n    &:hover\n        background: #E8ECF1\n        border-radius: 20px\n\n.listBox\n    width: 228px\n    background-color: #F7F7F7\n  \n.listMenu\n    padding-bottom: 0px !important\n\n.closeIcon\n    float: right !important\n    width: 60px !important\n    background-color: #F7F7F7\n    \n.listText\n    font-weight: 400\n    font-size: 16px\n.leftDrawer\n    & .MuiPaper-root\n        top:50px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
