// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 28px;
  padding-right: 28px;
}

.moreLoad {
  margin-top: 1.3%;
  margin-bottom: 1.3%;
}

.tableWrapper {
  overflow-y: auto;
  min-width: auto;
  height: 100%;
}

.langHeaderWrapper {
  height: 100%;
}

.backButtonWrapper {
  display: flex;
}

.typoLoadMore {
  font-size: 14px !important;
  font-weight: 700 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/MainScreen.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AACA;EACI,gBAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,eAAA;EACA,YAAA;AAGJ;;AADA;EACI,YAAA;AAIJ;;AAFA;EACI,aAAA;AAKJ;;AAHA;EACI,0BAAA;EACA,2BAAA;AAMJ","sourcesContent":[".MainScreen\n    display: flex\n    flex-direction: column\n    justify-content: center\n    padding-left: 28px\n    padding-right: 28px    \n\n.moreLoad\n    margin-top: 1.3%\n    margin-bottom: 1.3%\n\n.tableWrapper\n    overflow-y: auto\n    min-width: auto\n    height: 100%\n\n.langHeaderWrapper\n    height: 100%\n\n.backButtonWrapper\n    display: flex\n\n.typoLoadMore\n    font-size: 14px !important\n    font-weight: 700 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
