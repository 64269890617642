// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.head-filters-cont {
  width: 90%;
}

.heading-style-2 {
  text-align: left;
  padding: 10px 0;
  font-weight: bold;
}

.filter-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-wrapper {
  text-align: right;
  margin-top: 50px;
  margin-bottom: 20px;
}

.link-style {
  text-decoration: none;
}

.button-style {
  color: white;
  background-color: #003057 !important;
  width: 154px;
  height: 30px;
}

#mutiple-select-label {
  font-weight: bold;
  color: black;
}

#mutiple-select-only-label {
  font-weight: bold;
  color: black;
  text-transform: capitalize;
}

.second-page-card {
  border: 2px solid red;
}

.listPopup {
  text-align: left;
}

.wrapperList {
  margin-left: 40px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/LanguageDatabaseDashboard.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ;;AACA;EACI,UAAA;AAEJ;;AAAA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AAGJ;;AADA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AAIJ;;AAFA;EACI,iBAAA;EACA,gBAAA;EACA,mBAAA;AAKJ;;AAHA;EACI,qBAAA;AAMJ;;AAJA;EACI,YAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;AAOJ;;AALA;EACI,iBAAA;EACA,YAAA;AAQJ;;AANA;EACI,iBAAA;EACA,YAAA;EACA,0BAAA;AASJ;;AAPA;EACI,qBAAA;AAUJ;;AARA;EACI,gBAAA;AAWJ;;AATA;EACI,iBAAA;AAYJ","sourcesContent":[".container\n    height: 100%,\n    width: 100%,\n    display: flex\n    align-items: center\n    justify-content: center\n    flex-direction: column\n\n.head-filters-cont\n    width: 90%\n\n.heading-style-2\n    text-align: left\n    padding: 10px 0\n    font-weight: bold,\n\n.filter-items\n    display: flex\n    align-items: center\n    justify-content: space-between   \n\n.button-wrapper\n    text-align: right\n    margin-top: 50px\n    margin-bottom: 20px\n\n.link-style\n    text-decoration: none,\n\n.button-style\n    color: white,\n    background-color: #003057 !important\n    width: 154px\n    height: 30px\n\n#mutiple-select-label\n    font-weight: bold\n    color: black\n\n#mutiple-select-only-label\n    font-weight: bold\n    color: black\n    text-transform: capitalize\n\n.second-page-card\n    border: 2px solid red\n\n.listPopup\n    text-align: left\n\n.wrapperList\n    margin-left: 40px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
