// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#selectBox {
  width: 305px;
  display: flex;
  align-items: center;
}

.typoMenuItemOptions {
  font-size: 0.875rem;
}

.placeholderSelect {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/SelectCustom.sass"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":["#selectBox\n    width: 305px   \n    display: flex\n    align-items: center\n\n.typoMenuItemOptions\n    font-size: 0.875rem\n\n.placeholderSelect\n    font-size: 14px "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
