// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.sidebar-backBtn {
  width: 130px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.heading-style {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.padding-right {
  padding-right: 110px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/SubHeader.sass"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,WAAA;AACJ;;AACA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAGJ;;AADA;EACI,oBAAA;AAIJ","sourcesContent":[".inner-container\n    margin-top: 10px\n    display: flex\n    align-items: center\n    justify-content: flex-start\n    width: 100%\n\n.sidebar-backBtn\n    width: 130px\n    height: 35px\n    display: flex\n    align-items: center\n    justify-content: flex-start\n\n.heading-style\n    text-align: center\n    display: flex\n    align-items: center\n    justify-content: center\n    width: 100%\n\n.padding-right\n    padding-right: 110px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
