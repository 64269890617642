// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardItem-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  text-align: center;
  cursor: default;
}

.paper-style {
  padding: 50px 10px;
  height: 72px;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-style {
  font-size: 100px;
}

.card-heading {
  width: 100%;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/CardItem.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AACA;EACI,kBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,gBAAA;AAGJ;;AADA;EACI,WAAA;EACA,iBAAA;AAIJ","sourcesContent":[".cardItem-container\n    display: flex\n    align-items: center\n    justify-content: center\n    flex-wrap: wrap\n    padding: 10px\n    text-align: center\n    cursor: default\n\n.paper-style\n    padding: 50px 10px,\n    height: 72px,\n    width: 190px,\n    display: flex,\n    flex-direction: column,\n    justify-content: center,\n    align-items: center,\n    cursor: pointer\n\n.icon-style\n    font-size: 100px\n\n.card-heading\n    width: 100%,\n    padding-top: 10px,\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
