// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackIcon {
  padding-right: 3px;
  color: black;
}

.BackText {
  color: black;
  font-weight: 400;
  font-size: 14px !important;
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/BackButton.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,YAAA;AACJ;;AAAA;EACI,YAAA;EACA,gBAAA;EACA,0BAAA;EACA,0BAAA;AAGJ","sourcesContent":[".BackIcon\n    padding-right: 3px\n    color: black\n.BackText\n    color: black\n    font-weight: 400\n    font-size: 14px !important\n    text-transform: capitalize"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
