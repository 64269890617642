// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer {
  position: absolute;
  width: 100%;
  margin-left: 28px;
  margin-right: 29px;
}

.table {
  background: #E8ECF1;
}

.tableRow {
  position: absolute;
  top: 290px;
  width: 95% !important;
}

.headcell {
  font-weight: 700 !important;
  font-style: normal !important;
}

.viewButton {
  text-transform: capitalize !important;
  border: 1.5px solid #003057 !important;
  border-radius: 4px !important;
  color: #003057 !important;
  height: 30px;
}

.tableBox {
  height: 100px;
}

.buttonView {
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/LangTable.sass"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AACA;EACI,mBAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,UAAA;EACA,qBAAA;AAGJ;;AAAA;EACI,2BAAA;EACA,6BAAA;AAGJ;;AADA;EACI,qCAAA;EACA,sCAAA;EACA,6BAAA;EACA,yBAAA;EACA,YAAA;AAIJ;;AAFA;EACI,aAAA;AAKJ;;AAHA;EACI,gBAAA;AAMJ","sourcesContent":[".tableContainer\n    position: absolute\n    width: 100%\n    margin-left: 28px\n    margin-right: 29px\n    \n.table\n    background: #E8ECF1\n\n.tableRow\n    position: absolute\n    top: 290px\n    width: 95% !important\n\n\n.headcell\n    font-weight: 700 !important\n    font-style: normal !important\n\n.viewButton\n    text-transform: capitalize !important\n    border: 1.5px solid #003057 !important\n    border-radius: 4px !important\n    color: #003057 !important\n    height: 30px\n\n.tableBox\n    height: 100px\n\n.buttonView\n    font-weight: 500\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
