// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonStyle {
  padding: 10px 20px 20px;
}

.wrapper {
  width: 500px;
  text-align: center;
}

.contentStyle {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}

.iconClose {
  float: right;
  cursor: pointer;
  color: #767676;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/AlertDialog.sass"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AACA;EACI,YAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,0BAAA;EACA,eAAA;AAGJ;;AAAA;EACI,YAAA;EACA,eAAA;EACA,cAAA;AAGJ","sourcesContent":[".buttonStyle\n    padding: 10px 20px 20px\n\n.wrapper\n    width: 500px\n    text-align: center\n  \n.contentStyle\n    color: rgba(0, 0, 0, 0.87)\n    font-size: 14px\n   \n\n.iconClose\n    float: right\n    cursor: pointer\n    color: #767676"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
