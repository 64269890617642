// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-container {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Cards.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAEJ","sourcesContent":[".cards-container\n    padding: 10px\n    display: flex\n    align-items: center\n    justify-content: center\n    padding: 10px\n\n.grid-container\n    display: flex\n    align-items: center\n    justify-content: center \n    padding: 10px\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
