// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadMore {
  width: 145px;
  text-transform: capitalize !important;
  border: 1.5px solid #003057 !important;
  border-radius: 4px !important;
  color: #003057 !important;
}

.m-right {
  height: 38px;
  margin-right: 10px !important;
}

.lowercase {
  text-transform: capitalize;
  text-decoration: underline;
  font-size: 5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ButtonCustom.sass"],"names":[],"mappings":"AAAA;EACQ,YAAA;EACA,qCAAA;EACA,sCAAA;EACA,6BAAA;EACA,yBAAA;AACR;;AACA;EACQ,YAAA;EACA,6BAAA;AAER;;AAAA;EACQ,0BAAA;EACA,0BAAA;EACA,cAAA;AAGR","sourcesContent":[".loadMore\n        width: 145px\n        text-transform: capitalize !important\n        border: 1.5px solid #003057 !important\n        border-radius: 4px !important\n        color: #003057 !important \n\n.m-right\n        height: 38px,\n        margin-right: 10px !important\n\n.lowercase\n        text-transform: capitalize\n        text-decoration: underline\n        font-size: 5px\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
