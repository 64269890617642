// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appbar {
  height: 49.78px;
}

.flexbox {
  height: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #003057;
}

.link-style {
  color: white;
  padding-top: 3.5px;
}

.nav-title {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Navbar.sass"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AACA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,YAAA;EACA,kBAAA;AAGJ;;AADA;EACI,YAAA;AAIJ","sourcesContent":[".appbar\n    height: 49.78px,\n\n.flexbox\n    height: 100%\n    padding: 6px,\n    display: flex,\n    align-items: center,\n    justify-content: space-between,\n    background-color: #003057,\n    \n.link-style\n    color: white,\n    padding-top: 3.5px,\n        \n.nav-title\n    color: white\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
