// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard-heading {
  padding-top: 20px;
  text-align: center;
}

.head-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70%;
}

.icon-style {
  font-size: 50px;
}

.snackbar {
  color: "#ffffff";
  minWidth: "200px";
  maxWidth: "400px";
  boxShadow: "0 0 6px 0 #dbe7df";
  padding: "15px";
  fontSize: "14px";
  fontWeight: 500;
  letterSpacing: "0.25px";
  display: "flex";
  borderRadius: "6px";
  justifyContent: "center";
  alignContent: "center";
  margin: "0 auto";
}

.success {
  background: "#2E7D32";
}

.error {
  background: "#D32F2F";
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Dashboard.sass"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACJ;;AACA;EACI,iBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,WAAA;AAGJ;;AADA;EACI,eAAA;AAIJ;;AAFA;EACI,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;EACA,mBAAA;EACA,wBAAA;EACA,sBAAA;EACA,gBAAA;AAKJ;;AAJA;EACI,qBAAA;AAOJ;;AANA;EACI,qBAAA;AASJ","sourcesContent":[".dashboard-wrapper\n    height: 100vh,\n    display: flex\n    flex-direction: column\n    justify-content: flex-start\n\n.dashboard-heading\n    padding-top: 20px\n    text-align: center\n\n.head-card-wrapper\n    display: flex\n    flex-direction: column\n    justify-content: space-evenly\n    height: 70%\n\n.icon-style\n    font-size: 50px,\n\n.snackbar\n    color: '#ffffff'\n    minWidth: '200px'\n    maxWidth: '400px'\n    boxShadow: '0 0 6px 0 #dbe7df'\n    padding: '15px'\n    fontSize: '14px'\n    fontWeight: 500\n    letterSpacing: '0.25px'\n    display: 'flex'\n    borderRadius: '6px'\n    justifyContent: 'center'\n    alignContent: 'center'\n    margin: '0 auto'\n.success\n    background: '#2E7D32'\n.error\n    background: '#D32F2F'"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
